const doc = document
const html_class = doc.querySelector('html').classList
const body = doc.body
const win = window
// let lastX = -1
let lastY = -1
let lastW = win.innerWidth
let lastH = win.innerHeight
let banner = doc.querySelector('#app > header.banner')

function update()
{
  // const x = win.scrollX
  const y = win.scrollY
  const w = win.innerWidth
  const h = win.innerHeight
  switch (true)
  {
    // case x !== lastX:
    case y !== lastY:
    case w !== lastW:
    case h !== lastH:
      // console.log({x, y})
      // lastX = x
      let isAtTop = y === 0
      const isAtBottom = win.innerHeight + y === body.scrollHeight
      const isScrollingDown = y > lastY
      const isScrollingUp = y < lastY
      html_class.toggle('scroll-at-top', isAtTop)
      html_class.toggle('scroll-not-top', !isAtTop)
      html_class.toggle('scroll-at-bottom', isAtBottom)
      html_class.toggle('scroll-not-bottom', !isAtBottom)

      html_class.toggle('is-scrolling-down', isScrollingDown)
      html_class.toggle('not-scrolling-down', !isScrollingDown)
      html_class.toggle('is-scrolling-up', isScrollingUp)
      html_class.toggle('not-scrolling-up', !isScrollingUp)

      // lastX = X
      lastY = y
      lastW = w
      lastH = h
  }
  requestUpdate()
}

function requestUpdate()
{
  if ('requestAnimationFrame' in win)
  {
    return requestAnimationFrame(update)
  }

  return setTimeout(update, 1000 / 60)
}

requestUpdate()
